export enum Language {
    en = 'en',
    es = 'es',
    pt = 'pt',
    de = 'de',
    it = 'it',
    fr = 'fr',
    my = 'my',
    sw = 'sw',
    sq = 'sq',
    ko = 'ko',
    es_mx = 'es_mx'
}

export interface Currency {
    code: string;
    name: string;
    fractionSize: number;
    symbol: string;
}
